.App {
  text-align: center;
}

/* Cookie Modal Overlay */
.cookie-modal-overlay {
  background-color: transparent; /* No background overlay needed */
  z-index: 9999;
}

/* Cookie Modal Fixed at Bottom Right */
.cookie-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  border: 1px solid #ff0000;
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(50px);
  animation: fadeUp 0.5s ease-out forwards;
  z-index: 9999; /* Ensures it stays above other content */
}

/* Cookie Modal Title */
.cookie-modal-content h2 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: bold;
}

.learn-more-cookie {
  font-weight: 500;
}

/* Cookie Modal Text */
.cookie-modal-content p {
  color: #ddd;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

/* Cookie Modal Buttons */
.cookie-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.accept-button {
  background-color: #ff0000;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  width: 48%;
}

.manage-button {
  background-color: #444;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  width: 48%;
}

/* Hover effect for Accept Button */
.accept-button:hover {
  background-color: #cc0000; /* Darker red on hover */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Red glow effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Hover effect for Manage Button */
.manage-button:hover {
  background-color: #666; /* Lighter gray on hover */
  box-shadow: 0 0 10px rgba(68, 68, 68, 0.5); /* Gray glow effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}


/* Fade-up Animation */
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .cookie-modal {
    width: 90%;
    right: 5%;
    bottom: 20px;
    padding: 15px;
  }

  .cookie-modal-content h2 {
    font-size: 1rem;
  }

  .cookie-modal-content p {
    font-size: 0.8rem;
  }

  .accept-button, .manage-button {
    padding: 8px;
    font-size: 0.8rem;
  }
}


/* Slide from left */
.slide-left-in {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-out {
  transform: translateX(-100%);
  opacity: 0;
}

/* Slide from right */
.slide-right-in {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-out {
  transform: translateX(100%);
  opacity: 0;
}

/* Fade in and scale up */
.fade-scale-in {
  transform: scale(1); /* Original size */
  opacity: 1; /* Fully visible */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.fade-scale-out {
  transform: scale(0.8); /* Scaled down */
  opacity: 0; /* Hidden */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}


/* Smooth transitions */
.transition-transform {
  transition: transform 1s ease, opacity 1s ease;
}

.questions-container{
  color: #808080 !important;
}
.active-question {
  color: #FC0205 !important;
}

@media only screen and (max-width: 900px) {
  .mission-vision-container{
    flex-wrap: wrap;
    justify-content: center;
  }
  }

  @media only screen and (max-width: 600px) {
    #top-section{
      display: none;
    }
    .section-container{
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .our-services-card{
      height: 10rem;
    }
    .mission-vision-container{
      padding: 0;
    }
  }

  @media only screen and (max-width: 460px) {
    .hero-text-container{
      margin-top: 5rem;
      width: 92%;
      padding: 1.5rem;
    }
  }
  @media only screen and (max-width: 350px) {
    .hero-text-container{
      width: 95%;
    }
  }

  /* custom media query */
  @media only screen and (max-width: 691px) {
    .contact-info-text{
      justify-content: left !important;
      text-align: left;
    }
  }
  
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #FC0205;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}