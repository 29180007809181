/* General Styles */
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/* Mobile menu default state (closed) */
.dropdown-open, 
.dropdown-closed {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.6s ease, opacity 0.6s ease;
}

/* When the menu is toggled open */
.dropdown-open {
  max-height: 500px;  /* Adjust height based on content */
  opacity: 1;
  transition: max-height 0.6s ease, opacity 0.6s ease;
}

/* When the menu is toggled closed */
.dropdown-closed {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

/* Hamburger Menu Styles */
.hamburger {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: relative;
}

.hamburger .bar {
  width: 30px;
  height: 4px;
  background-color: white;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2, .bar3 {
  top: 13.5px;
}

.bar4 {
  bottom: 0;
}

/* Hamburger - Toggle (Checked) */
.checkbox1:checked + label > .hamburger1 > .bar1 {
  transform: rotate(45deg);
  transform-origin: 5%;
  width: 41px;
}

.checkbox1:checked + label > .hamburger1 > .bar2 {
  transform: translateX(-40px);
  background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar3 {
  transform: translateX(40px);
  background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar4 {
  transform: rotate(-45deg);
  transform-origin: 5%;
  width: 41px;
}

@media (max-width: 768px) {
  .about-us-text {
    margin-top: -1rem;
  }

  .services-text {
    margin-bottom: 1rem;
  }
}
