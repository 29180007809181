@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* responsiveness */

/* home */
@media only screen and (max-width: 767px) {
.hero-text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}

@media only screen and (min-width: 2000px) {
  .page-hero{
    height: 500px !important;
    max-height: 500px !important;
  }
  .hero-homepage{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
  }
}
@media only screen and (min-width: 1700px) {
  .hero-homepage{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1563px;
  }
}